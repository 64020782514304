<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedToilettages"
      :headers="headers"
      table-class="table-striped table-hover"
      @search="search"
    >
      <template slot="actions">
        <router-link
          :to="{name: 'add-toilettage'}"
          class="btn btn-outline-primary btn-icon"
        >
          <i class="icofont icofont-plus" />
        </router-link>
      </template>
    </data-table>
  </div>
</template>
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import moment from 'moment'
  import DataTable from '../../../components/dataTable/local.vue'
  import navbar from '../../../components/navbar.vue'
  import { TEXT_TYPE, COMPONENT_TYPE, DATE_TYPE} from '../../../components/dataTable/dataType'
  const Animal = () => import('../../../components/sanitaire/animal.vue')
  const CreateBy = () => import('../../../components/sanitaire/createdBy.vue')
  const Activities = () => import('../../../components/sanitaire/toilettage/activities.vue')
  const Actions = () => import('../../../components/sanitaire/toilettage/actions.vue')
  export default {
      components: {navbar,  DataTable },
      data(){
          return {
            navbarItems: [
                  { libelle: 'Sanitaire' },
                  { libelle: 'Toiletage' }
            ],
            pageIcon: 'la-bath',
            pageTitle: 'Toilettage',
            pageDescription: 'Toilettages des animaux', 
            nom: null,
            libelle: null,
            description: null,
            model: null,
            searchToilettages: []
          }
      },
      watch: {
        toilettages: {
          deep: true,
          handler(){
              this.searchToilettages = this.toilettages
          }
        }
      },
      mounted(){
        this.searchToilettages = this.toilettages
      },
      methods: {
          ...mapMutations({
              done: 'DONE'
          }),
          search(payload){
            const query = payload.search
              if(query === '') this.searchToilettages = this.toilettages
              else{
                this.searchToilettages = this.toilettages.filter(toilettage =>
                  this.$options.filters.deepSearch(toilettage, query)|| 
                  this.$options.filters.deepSearch(this.animaux.find(animal => animal.uid === toilettage.animal), query)
                )
              }
          }
      },
      computed: {
          ...mapGetters({
              toilettages: 'sanitaire/toilettages',
              animaux: 'identification/animaux',
              is_super_admin: 'auth/is_super_admin',
              is_veterinaire: 'auth/is_veterinaire',
              is_assistant_veto: 'auth/is_assistant_veterinaire',
              is_assistant_veto_major: 'auth/is_assistant_veto_major'
          }),
          sortedToilettages(){
            return [...this.searchToilettages].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
          },
          
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                  {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                  {label: 'Activités', name: 'activities', type: COMPONENT_TYPE, component: Activities, style: {width: "20%"}},
                  {label: 'Date Toilettage', name: 'dateToilettage', type: DATE_TYPE},
                  {label: 'Date Rappel', name: 'dateRappel', type: DATE_TYPE},
                  {label: 'Createur', name: 'createby', type: COMPONENT_TYPE, component: CreateBy},
                  {label: 'Date création', name: 'createdAt', type: DATE_TYPE},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions, style: {width: "15%"}}
              ]
          },
          canAdd(){
            return this.is_super_admin || this.is_veterinaire || this.is_assistant_veto || this.is_assistant_veto_major
          }
      }
  }
  </script>